import { useChain } from "@/hooks/Web3ModalProvider";
import { useCallback } from "react";
import { TransactionMethod } from "@/hooks//useTransaction";
import { ethers } from "ethers";

import TokenABI from "@/abi/abi.token.json";
import { useContract } from "./useContract";
import { AppNumber } from "@/lib/providers/math/app-number.provider";

export const useApproveToken = () => {
  const { desiredChain } = useChain();
  const { hash, isError, isIdle, isPending, isSuccess, writeContract } =
    useContract(TransactionMethod.Approve);

  const approve = useCallback(
    ({
      tokenAddress,
      spenderAddress,
      approvedAmount,
      isApprovedAll = false,
    }: {
      tokenAddress: string;
      spenderAddress: string;
      approvedAmount: AppNumber;
      isApprovedAll?: boolean;
    }) => {
      writeContract({
        address: tokenAddress,
        abi: TokenABI,
        functionName: "approve",
        args: [
          spenderAddress,
          isApprovedAll || !approvedAmount
            ? ethers.MaxInt256
            : approvedAmount?.toBigNumber(),
        ],
      } as any);
    },
    [desiredChain, writeContract]
  );

  return { hash, isPending, isSuccess, isError, isIdle, approve };
};
